@import "@/styles/mixins/common.scss";
.sliderCaptchaBox {
  margin: 30px;
  .sliderCaptchaTitle {
    width: 320px;
    font-size: 20px;
    font-weight: 500;
    margin: auto auto 10px;
  }
}
.sliderCaptcha {
  margin: auto;
}
