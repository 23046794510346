@import "../variable/color.scss";

@mixin ellipsis($clamp: 3) {
  display: -webkit-box;
  overflow: hidden;
  white-space: normal !important;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $clamp;
  -webkit-box-orient: vertical;
}

.flex_row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex_column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex_row_between {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
}


.trans-fadeout {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.absolute_center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

// ::-webkit-scrollbar {
//   width: 6px;
// }

// /* 滚动条滑块 */
// ::-webkit-scrollbar-thumb {
//   border-radius: 3px;
//   -webkit-transition: width 0.1s, color 0.1s;
//   transition: width 0.1s, color 0.1s;
//   position: absolute;
//   top: 0;
//   right: 0;
//   background: rgba(0, 0, 0, 0.12);
//   cursor: pointer;

//   &:hover {
//     width: 12px;
//   }
// }


