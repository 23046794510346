@import "@/styles/mixins/common.scss";
.scrollTop {
    // position: fixed;
    // right: 20px;
    // bottom: 200px;
    text-align: center;

    :global {
        .backToTop {
            background-color: #5f5e66;
            color: #fff;

            &:hover {
                background-color: #5f5e66;
            }
        }
    }
}