@import "@/styles/mixins/common.scss";
@import '@/styles/variable';

.contentWrapper {
  max-width: 500px;
  width: 80vw;
  margin: auto;
  background-color: #fff;

  .loginTitle {
    background-image: url('https://aicdn.feilianyun.cn/statics/ai-paint-pc/images/login_banner.png');
    height: 157px;
    background-size: 100% 100%;
  }

  .loginContent {
    padding: 24px;
    position: relative;
    min-height:100px;
  }

  .verifyCodeWrapper {
    position: relative;

    .code {
      position: absolute;
      top: 32px;
      right: 16px;
      font-size: 14px;
      cursor: pointer;
      color: #39a2af;
    }
  }

	.invitedCode {
		display: flex;
		align-items: center;
		margin: 10px 0 0 6px;
		color: #2d2d2d;
		font-size: 20px;
		.codeNumber {
			margin-left: 10px;
			color: #5e5c5c;
			font-weight: bolder;
		}
	}

  .btn {
    background-color: $theme_attach_color;
    height: 48px;
    margin-top: 36px;

    &:hover {
      background-color: $theme_attach_color;
    }
  }

  .tips {
    margin-top: 12px;
    font-size: 14px;
    color: #999;
    text-align: center;
  }
  .protocol {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }

  .extraLoginType {
    margin-top: 48px;

    .title {
      position: relative;
      text-align: center;
      font-size: 14px;
      color: #999;

      &::before,
      &::after {
        position: absolute;
        content: "";
        width: 45%;
        height: 1px;
        background-color: #bfbfbf;

        top: 50%;
      }

      &::before {
        left: -24px;
      }

      &::after {
        right: -24px;
      }
    }

    .types {
      margin-top: 16px;
      justify-content: space-around;
      display: flex;
      color: #39a2af;
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;
    }
  }
}
