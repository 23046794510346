@import "@/styles/mixins/common.scss";
.selfTextarea {
  width: 100%;
  .textarea {
    textarea {
      color: #fff;
    }
  }
  .footer {
    padding: 5px 0;
    overflow: hidden;
    .countWrapper {
      display: flex;
      align-items: center;
      float: right;
      font-size: 16px;
      .splitLine {
        margin: 0 2px;
      }
    }
    .countLine {
      margin: 0 5px;
    }
    .clear {
      display: flex;
      align-items: center;
      cursor: pointer;
      .trashIcon {
        font-size: 20px;
      }
    }
  }
}