@import "@/styles/mixins/common.scss";
@import "@/styles/variable";

.versionUpdateModal {
    display: none;
    position: fixed;
	right: 40px;
	bottom: 40px;
    color: #fff;
    width: 358px;
    min-height: 199px;
    padding: 24px 24px 0;
    background: linear-gradient(0deg,rgba(0,0,0,.78),rgba(0,0,0,.78)),linear-gradient(107.13deg,rgba(54,212,199,.7) -28.87%,rgba(196,180,168,.7) 48.86%,rgba(83,185,193,.7) 123.42%);
    border: 1px solid hsla(0,0%,100%,.2);
    border-radius: 18px;
    z-index: $versionUpdate;

    &.open {
        display: block;
    }

    .title {
        margin-bottom: 10px;

        img {
            width: 149px;
            height: 23px;
        }
    }

    .subTitle {
        margin-bottom: 20px;
    }
    .content{
        line-height: 2em;
    }

    .footer {
        height: 65px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        button {
            width: 77px;
            height: 29px;
            background: rgba(71,255,241,.15);
            border: 1px solid $theme_attach_color;
            -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
            border-radius: 100px;
            font-size: 12px;
            line-height: 29px;
            text-align: center;
            color: #fff;
            cursor: pointer;
        }
    }

}
