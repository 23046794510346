@import "@/styles/mixins/common.scss";
@import '@/styles/variable';

.section-box {
  margin-top: 18px;
  height: 100%;

  .section-box-title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: #000;

    .title-wrapper {
      color: #fff;
      display: flex;
      align-items: center;

      &.required {
        padding-left: 20px;

        &:after {
          content: '*';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          color: $theme_attach_color;
          ;
        }
      }

      .title-main {
        font-size: 15px;
        line-height: 1;
        position: relative;
        font-weight: normal;
      }

      .title-main::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        // background-image: linear-gradient(45deg, rgba(209, 238, 77, 0.2), rgba(54, 212, 199, 0.4), rgba(243, 137, 183, 0.6));
        background-color: #36d4c7;
        opacity: 0.3;
        height: 6px;
        width: 100%;
      }

      .question {
        margin-left: 4px;
        font-size: 18px;
        cursor: pointer;
      }

      .feedback {
        margin-left: 4px;
        color: #999;
        cursor: pointer;
        font-size: 12px;
        font-weight: normal;
      }
    }
  }

  .section-box-content {
    max-height: 90%;
  }
}

.feedbackModal {
  .tips {
    margin-bottom: 24px;
  }
}