@import "@/styles/mixins/common.scss";
@import "@/styles/variable";

.flyModal {
  :global {
    .MuiDialogContent-root {
      padding-top: 0;
    }

    .MuiButton-text{
      color: $theme_attach_color;

    }

    .MuiDialogContent-root {
      color: #999;
    }

    .MuiDialog-paper {
      min-width: 520px;
      background-color: #232326;
      color: #fff
    }
  }
}