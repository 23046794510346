@import "@/styles/mixins/common.scss";
.inputWrapper {
    position: relative;

    .count {
        position: absolute;
        right: 16px;
        bottom: 16px;
    }

    :global {
        .cancelIcon {
            position: absolute;
            right: 16px;
            top: 32px;
            color: #999;
            cursor: pointer;
        }
    }

    :global {
        .Mui-focused{
            color:#36d4c7 !important;
        }
        .Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: #36d4c7 !important;
        }
    }
}